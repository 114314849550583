$(document).ready(function () {
    // Active menu navigazione
    // --------------------------------------------------
    var path = window.location.pathname;

    if (path.indexOf("index") !== -1) {
        $("#u-home a").addClass("active");
    }
    else if (path.indexOf("about") !== -1) {
        $("#u-about a").addClass("active");
    }
    else if (path.indexOf("news") !== -1) {
        $("#u-news a").addClass("active");
    }
    else if (path.indexOf("products") !== -1) {
        $("#u-products a").addClass("active");
    }
    else if (path.indexOf("contact") !== -1) {
        $("#u-contact a").addClass("active");
    }
    else {
        //
    }

    // SMOOTH Scrolling
    $('.js-smooth').smoothScroll();

    // Sticky footer
    /* --------------------------------------------------*/

    function stickyFooter(delay) {
      var
        elNavProdutcs = $("#nav-products"),
        sidebarHeight = elNavProdutcs.height(),
        bodyHeight = $('body').height(),
        elPastry = $('#nav-pastry'),
        elFooter = $('footer'),
        lastnavHeight = elPastry.height(),
        delta = elFooter.offset().top - elPastry.offset().top,
        margin = lastnavHeight - delta,
        footerHeight = elFooter.height() + 20,
        elMainContent = $('.main-content'),
        currentPaddingS = elMainContent.css('padding-bottom').replace('px', ''),
        currentPadding = parseInt(currentPaddingS);

      //console.log(footerHeight, currentPadding);
      if (footerHeight > currentPadding) {
        setInterval(function() {
          elMainContent.css('padding-bottom', footerHeight);
        }, delay??300);
      }
    }

    stickyFooter(1);

    $(window).resize(function () {
        stickyFooter();
    });
});
